<template>
  <ion-page>
    <!-- Header -->
    <ion-header id="dark-header">
      <ion-toolbar class="text-center">
        <ion-buttons slot="start">
          <ion-button @click.prevent="goBack()">
            <ion-icon :icon="chevronBack"></ion-icon>
          </ion-button>
        </ion-buttons>
        <!--<ion-buttons slot="end">
          <ion-button @click.prevent="updateRoute('/create-message')">
            <ion-icon :ios="add" :md="add"></ion-icon>
          </ion-button>
        </ion-buttons>-->

        <ion-title>Admin</ion-title>
      </ion-toolbar>
    </ion-header> 
    <!-- Page Content -->
    <ion-content>
      <!-- Home Feed -->
      <div v-if="isAdmin === true" class="app-max-width">
          <!-- Posts -->
          <ion-item button detail color="light" @click.prevent="updateRoute('admin-posts')">
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-label>Posts</ion-label>
          </ion-item>
          <!-- Pages -->
          <ion-item button detail color="light" @click.prevent="updateRoute('admin-pages')">
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-label>Pages</ion-label>
          </ion-item>
          <!-- Categories -->
          <ion-item button detail color="light" @click.prevent="updateRoute('admin-categories')">
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-label>Categories</ion-label>
          </ion-item>
          <!-- Scenes -->
          <ion-item button detail color="light" @click.prevent="updateRoute('admin-scenes')">
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-label>Scenes</ion-label>
          </ion-item>
          <!-- Genres -->
          <ion-item button detail color="light" @click.prevent="updateRoute('admin-genres')">
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-label>Genres</ion-label>
          </ion-item>
      </div>
      <div v-else class="app-max-width">
        Not authorized
      </div>
    </ion-content>

  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, IonItem, IonLabel } from '@ionic/vue';
import { chevronBack, add } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import doAuth from '../../services/auth';
import { useRouter } from 'vue-router';
//import MessageList from '../../components/MessageList.vue';

export default defineComponent({
  name: 'Admin',
  components: {
    IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, IonItem, IonLabel
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();
    const isAdmin = ref(true);

    function updateRoute(val) {
      router.push(val)
    }

    function goBack() {
      router.go(-1)
    }

    return {
      chevronBack, add, router, updateRoute, goBack, isAdmin
    }
  }
});
</script>

<style scoped>

</style>